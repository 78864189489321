.HeaderTextBox {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

.HeaderTextEntry:first-child {
    letter-spacing: 1px;
    flex-basis: 150px;
}

.HeaderTextEntry:last-child {
    letter-spacing: 1px;
}

.FilterAccordionSummary {
    color: white !important;
    background-color: rgb(80, 95, 116);
    background: rgb(80, 95, 116);
    background: linear-gradient(rgba(80, 95, 116, 0.75), rgba(80, 95, 116, 0.7), rgba(80, 95, 116, 0.75)) !important;
}