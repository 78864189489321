.UPSLinkBody {
  height: 100px;
  min-width: 280px;
  background-color: rgb(75, 30, 30);
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  padding-right: 5px;
  padding-left: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid rgba(0, 0, 0, 0);
  overflow: hidden;
}

.UPSLinkBody:hover {
  border: 2px solid goldenrod;
}
.UPSLinkBody:hover::before {
  content: '';
  display: block;
  position: absolute;
  background: linear-gradient(
    rgba(0, 0, 0, 0.2),
    rgba(255, 255, 255, 0.2),
    rgba(0, 0, 0, 0.2)
  );
  width: 250px;
  height: 70px;
  filter: blur(30px);
  transform: rotate(180deg);
  overflow: hidden;
}

.label {
  font-size: 16px;
  color: white;
}

.UPSLinkContent {
  height: 100%;
}

.form {
  text-align: center;
  width: calc(100% - 100px);
  display: block;
}

.logo {
  height: 100px;
  width: 100px;
}
