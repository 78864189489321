.barBody {
  width: 100%;
  height: 15px;
  position: relative;
  margin: 2rem 0 4rem 0;
}

.bar {
  top: 0;
  left: 0;
  width: 100%;
  height: 15px;
  background-color: rgba(0, 0, 0, 0.05);
  border: thin solid rgba(0, 0, 0, 0.1);
  position: absolute;
  border-radius: 0.25em;
  overflow: hidden;
  z-index: 1;

  --fill-timing: 500ms;
  --bar-width: 2em;
}

.barFill {
  background: rgb(171, 255, 54);
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  transition-duration: var(--fill-timing);
}

.fillContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.indicator {
  box-sizing: border-box;
  position: absolute;
  top: 0%;
  transform: translate(-50%, -33%);
  width: 2.5em;
  height: 2.5em;
  z-index: 2;
  background: white;
  border: 2px solid rgb(100, 100, 100);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: large;
}

.indicatorLabel {
  box-sizing: border-box;
  position: absolute;
  top: 225%;
  transform: translateX(-50%);
  width: max-content;
  height: min-content;
  background: none;
  font-size: 0.8rem;
  text-align: center;
  padding: 0 0.25rem;
  z-index: 2;
}

.indicatorLabel.active {
  font-weight: 600;
  font-size: 1rem;
}

.indicator.selected {
  border: 3px solid rgb(171, 255, 54);
  font-weight: 600;
}

.barSlider {
  position: absolute;
  height: 100%;
  width: var(--bar-width);
  background-color: rgba(0, 0, 0, 0.07);
  clip-path: polygon(40% 0%, 100% 0%, 60% 100%, 0% 100%);
  animation: slide-right 2000ms linear infinite;
  left: calc(-4 * var(--bar-width));
}

.loader {
  border: 0.25em solid #f3f3f3;
  border-top: 0.25em solid #3498db;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation: spin 1s cubic-bezier(0.33, 0.6, 0.73, 0.46) infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes slide-right {
  0% {
    left: calc(-4 * var(--bar-width));
  }

  100% {
    left: 100%;
  }
}
