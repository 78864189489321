.Popper {
    max-width: 325px;
    max-height: 235px;
    overflow-y: auto;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    font-size: 13.006px;
    line-height: 1.25;
    padding: 10px 15px;
    color: rgb(44, 44, 44);
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%) !important;
    text-align: justify;
}

.Popper p, .Popper span {
    font-size: 0.925rem;
    margin: 0;
}

.Popper h1, .Popper h2, .Popper h3, .Popper h4, .Popper h5, .Popper h6 {
    font-weight: bold;
    margin: 0 auto 5px auto;
}

.Popper h6 {
    font-size: 1rem;
    margin-top: 5px;
}

.Popper h5 {
    font-size: 1.15rem;
}

.Popper h4 {
    font-size: 1.35rem;
    margin-top: 5px;
}

.Popper h3 {
    font-size: 1.5rem;
    margin-top: 5px;
}
.Popper h2 {
    font-size: 2rem;
    margin-top: 5px;
}

.Popper h1 {
    font-size: 2.5rem;
}

.Popper hr {
    border-top: thin solid rgba(0, 0, 0, 0.5);
    width: 90%;
    margin: -0.2rem auto 0.5rem auto;
}

.PickerHeaderIcon {
    margin-bottom: 2px;
}
.PickerHeaderIcon:hover {
    cursor: help;
}