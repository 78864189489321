.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 250px;
  font-size: 16px;
}
.info {
  height: 35px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: normal;
  font-size: 14px;
}

.mainText {
  margin-left: 5px;
}

.switchContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  font-size: 13px;
}
