@-webkit-keyframes truck-skew {
  0%,
  15%,
  48%,
  75%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: skewX(-15deg);
    -moz-transform: skewX(-15deg);
    -ms-transform: skewX(-15deg);
    -o-transform: skewX(-15deg);
    transform: skewX(-15deg);
  }

  35%,
  38% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -moz-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -ms-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -o-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: skewX(-13deg);
    -moz-transform: skewX(-13deg);
    -ms-transform: skewX(-13deg);
    -o-transform: skewX(-13deg);
    transform: skewX(-13deg);
  }

  65% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -moz-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -ms-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -o-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: tskewX(-12deg);
    -moz-transform: tskewX(-12deg);
    -ms-transform: tskewX(-12deg);
    -o-transform: tskewX(-12deg);
    transform: tskewX(-12deg);
  }

  85% {
    -webkit-transform: skewX(-14deg);
    -moz-transform: skewX(-14deg);
    -ms-transform: skewX(-14deg);
    -o-transform: skewX(-14deg);
    transform: skewX(-14deg);
  }
}

@keyframes truck-skew {
  0%,
  15%,
  48%,
  75%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: skewX(-15deg);
    -moz-transform: skewX(-15deg);
    -ms-transform: skewX(-15deg);
    -o-transform: skewX(-15deg);
    transform: skewX(-15deg);
  }

  35%,
  38% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -moz-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -ms-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -o-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: skewX(-13deg);
    -moz-transform: skewX(-13deg);
    -ms-transform: skewX(-13deg);
    -o-transform: skewX(-13deg);
    transform: skewX(-13deg);
  }

  65% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -moz-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -ms-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -o-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: tskewX(-12deg);
    -moz-transform: tskewX(-12deg);
    -ms-transform: tskewX(-12deg);
    -o-transform: tskewX(-12deg);
    transform: tskewX(-12deg);
  }

  85% {
    -webkit-transform: skewX(-14deg);
    -moz-transform: skewX(-14deg);
    -ms-transform: skewX(-14deg);
    -o-transform: skewX(-14deg);
    transform: skewX(-14deg);
  }
}

@-webkit-keyframes wheel-front-bounce {
  0%,
  20%,
  53%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -moz-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -ms-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -o-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -10px, 0);
    -moz-transform: translate3d(0, -10px, 0);
    -ms-transform: translate3d(0, -10px, 0);
    -o-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -moz-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -ms-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -o-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -5px, 0);
    -moz-transform: translate3d(0, -5px, 0);
    -ms-transform: translate3d(0, -5px, 0);
    -o-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -1px, 0);
    -moz-transform: translate3d(0, -1px, 0);
    -ms-transform: translate3d(0, -1px, 0);
    -o-transform: translate3d(0, -1px, 0);
    transform: translate3d(0, -1px, 0);
  }
}

@keyframes wheel-front-bounce {
  0%,
  20%,
  53%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -moz-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -ms-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -o-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -10px, 0);
    -moz-transform: translate3d(0, -10px, 0);
    -ms-transform: translate3d(0, -10px, 0);
    -o-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -moz-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -ms-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -o-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -5px, 0);
    -moz-transform: translate3d(0, -5px, 0);
    -ms-transform: translate3d(0, -5px, 0);
    -o-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -1px, 0);
    -moz-transform: translate3d(0, -1px, 0);
    -ms-transform: translate3d(0, -1px, 0);
    -o-transform: translate3d(0, -1px, 0);
    transform: translate3d(0, -1px, 0);
  }
}

@-webkit-keyframes wheel-back-bounce {
  0%,
  25%,
  58%,
  85%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  45%,
  48% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -moz-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -ms-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -o-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -10px, 0);
    -moz-transform: translate3d(0, -10px, 0);
    -ms-transform: translate3d(0, -10px, 0);
    -o-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  75% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -moz-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -ms-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -o-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -5px, 0);
    -moz-transform: translate3d(0, -5px, 0);
    -ms-transform: translate3d(0, -5px, 0);
    -o-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  95% {
    -webkit-transform: translate3d(0, -1px, 0);
    -moz-transform: translate3d(0, -1px, 0);
    -ms-transform: translate3d(0, -1px, 0);
    -o-transform: translate3d(0, -1px, 0);
    transform: translate3d(0, -1px, 0);
  }
}

@keyframes wheel-back-bounce {
  0%,
  25%,
  58%,
  85%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  45%,
  48% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -moz-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -ms-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -o-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -10px, 0);
    -moz-transform: translate3d(0, -10px, 0);
    -ms-transform: translate3d(0, -10px, 0);
    -o-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  75% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -moz-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -ms-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -o-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -5px, 0);
    -moz-transform: translate3d(0, -5px, 0);
    -ms-transform: translate3d(0, -5px, 0);
    -o-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  95% {
    -webkit-transform: translate3d(0, -1px, 0);
    -moz-transform: translate3d(0, -1px, 0);
    -ms-transform: translate3d(0, -1px, 0);
    -o-transform: translate3d(0, -1px, 0);
    transform: translate3d(0, -1px, 0);
  }
}

@-webkit-keyframes body-bounce {
  0%,
  15%,
  48%,
  75%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  35%,
  38% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -moz-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -ms-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -o-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -10px, 0);
    -moz-transform: translate3d(0, -10px, 0);
    -ms-transform: translate3d(0, -10px, 0);
    -o-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  65% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -moz-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -ms-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -o-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -5px, 0);
    -moz-transform: translate3d(0, -5px, 0);
    -ms-transform: translate3d(0, -5px, 0);
    -o-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  85% {
    -webkit-transform: translate3d(0, -1px, 0);
    -moz-transform: translate3d(0, -1px, 0);
    -ms-transform: translate3d(0, -1px, 0);
    -o-transform: translate3d(0, -1px, 0);
    transform: translate3d(0, -1px, 0);
  }
}

@keyframes body-bounce {
  0%,
  15%,
  48%,
  75%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  35%,
  38% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -moz-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -ms-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -o-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -10px, 0);
    -moz-transform: translate3d(0, -10px, 0);
    -ms-transform: translate3d(0, -10px, 0);
    -o-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  65% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -moz-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -ms-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -o-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -5px, 0);
    -moz-transform: translate3d(0, -5px, 0);
    -ms-transform: translate3d(0, -5px, 0);
    -o-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  85% {
    -webkit-transform: translate3d(0, -1px, 0);
    -moz-transform: translate3d(0, -1px, 0);
    -ms-transform: translate3d(0, -1px, 0);
    -o-transform: translate3d(0, -1px, 0);
    transform: translate3d(0, -1px, 0);
  }
}

@-webkit-keyframes gas-first-flow {
  0% {
    opacity: 0;
    -webkit-transition-timing-function: linear;
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
  }

  50% {
    opacity: 1;
    -webkit-transition-timing-function: linear;
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: translate3d(-20px, -3px, 0);
    -moz-transform: translate3d(-20px, -3px, 0);
    -ms-transform: translate3d(-20px, -3px, 0);
    -o-transform: translate3d(-20px, -3px, 0);
    transform: translate3d(-20px, -3px, 0);
  }

  100% {
    opacity: 0;
    -webkit-transition-timing-function: linear;
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: translate3d(-40px, -6px, 0);
    -moz-transform: translate3d(-40px, -6px, 0);
    -ms-transform: translate3d(-40px, -6px, 0);
    -o-transform: translate3d(-40px, -6px, 0);
    transform: translate3d(-40px, -6px, 0);
  }
}

@keyframes gas-first-flow {
  0% {
    opacity: 0;
    -webkit-transition-timing-function: linear;
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
  }

  50% {
    opacity: 1;
    -webkit-transition-timing-function: linear;
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: translate3d(-20px, -3px, 0);
    -moz-transform: translate3d(-20px, -3px, 0);
    -ms-transform: translate3d(-20px, -3px, 0);
    -o-transform: translate3d(-20px, -3px, 0);
    transform: translate3d(-20px, -3px, 0);
  }

  100% {
    opacity: 0;
    -webkit-transition-timing-function: linear;
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: translate3d(-40px, -6px, 0);
    -moz-transform: translate3d(-40px, -6px, 0);
    -ms-transform: translate3d(-40px, -6px, 0);
    -o-transform: translate3d(-40px, -6px, 0);
    transform: translate3d(-40px, -6px, 0);
  }
}

@-webkit-keyframes gas-last-flow {
  0% {
    opacity: 0;
    -webkit-transition-timing-function: linear;
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: translate3d(30px, 0px, 0);
    -moz-transform: translate3d(30px, 0px, 0);
    -ms-transform: translate3d(30px, 0px, 0);
    -o-transform: translate3d(30px, 0px, 0);
    transform: translate3d(30px, 0px, 0);
  }

  50% {
    opacity: 1;
    -webkit-transition-timing-function: linear;
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: translate3d(10px, -5px, 0);
    -moz-transform: translate3d(10px, -5px, 0);
    -ms-transform: translate3d(10px, -5px, 0);
    -o-transform: translate3d(10px, -5px, 0);
    transform: translate3d(10px, -5px, 0);
  }

  100% {
    opacity: 0;
    -webkit-transition-timing-function: linear;
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: translate3d(0px, -10px, 0);
    -moz-transform: translate3d(0px, -10px, 0);
    -ms-transform: translate3d(0px, -10px, 0);
    -o-transform: translate3d(0px, -10px, 0);
    transform: translate3d(0px, -10px, 0);
  }
}

@keyframes gas-last-flow {
  0% {
    opacity: 0;
    -webkit-transition-timing-function: linear;
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: translate3d(30px, 0px, 0);
    -moz-transform: translate3d(30px, 0px, 0);
    -ms-transform: translate3d(30px, 0px, 0);
    -o-transform: translate3d(30px, 0px, 0);
    transform: translate3d(30px, 0px, 0);
  }

  50% {
    opacity: 1;
    -webkit-transition-timing-function: linear;
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: translate3d(10px, -5px, 0);
    -moz-transform: translate3d(10px, -5px, 0);
    -ms-transform: translate3d(10px, -5px, 0);
    -o-transform: translate3d(10px, -5px, 0);
    transform: translate3d(10px, -5px, 0);
  }

  100% {
    opacity: 0;
    -webkit-transition-timing-function: linear;
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transform: translate3d(0px, -10px, 0);
    -moz-transform: translate3d(0px, -10px, 0);
    -ms-transform: translate3d(0px, -10px, 0);
    -o-transform: translate3d(0px, -10px, 0);
    transform: translate3d(0px, -10px, 0);
  }
}

#Truck {
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: truck-skew;
  -moz-animation-name: truck-skew;
  -ms-animation-name: truck-skew;
  -o-animation-name: truck-skew;
  animation-name: truck-skew;
  width: 100px;
}

#Truck #FrontWheel {
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: wheel-front-bounce;
  -moz-animation-name: wheel-front-bounce;
  -ms-animation-name: wheel-front-bounce;
  -o-animation-name: wheel-front-bounce;
  animation-name: wheel-front-bounce;
}

#Truck #BackWheel {
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: wheel-back-bounce;
  -moz-animation-name: wheel-back-bounce;
  -ms-animation-name: wheel-back-bounce;
  -o-animation-name: wheel-back-bounce;
  animation-name: wheel-back-bounce;
}

#Truck #Body {
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: body-bounce;
  -moz-animation-name: body-bounce;
  -ms-animation-name: body-bounce;
  -o-animation-name: body-bounce;
  animation-name: body-bounce;
  fill: #d65600;
}

#Truck #GasFirst {
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 0.7s;
  -moz-animation-duration: 0.7s;
  -ms-animation-duration: 0.7s;
  -o-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: gas-first-flow;
  -moz-animation-name: gas-first-flow;
  -ms-animation-name: gas-first-flow;
  -o-animation-name: gas-first-flow;
  animation-name: gas-first-flow;
  fill: #dedede;
}

#Truck #GasLast {
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 0.8s;
  -moz-animation-duration: 0.8s;
  -ms-animation-duration: 0.8s;
  -o-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: gas-last-flow;
  -moz-animation-name: gas-last-flow;
  -ms-animation-name: gas-last-flow;
  -o-animation-name: gas-last-flow;
  animation-name: gas-last-flow;
  fill: #ececec;
}

#Truck {
  width: 65px;
  display: block;
}

#Container {
  width: 100%;
  /* height: 75px; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

#Card {
  position: relative;
  width: 35%;
  min-width: 275px;
  height: 100%;
  margin: -10px 0 10px 0;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  border: thin solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 20px rgb(158, 120, 120, 0.1);
  user-select: none;
  cursor: pointer;
  padding: 5px 0 8px 0;
  transition-duration: 200ms;
}

#Card:hover {
  animation-name: Shiver;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  border: thin solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 20px rgb(158, 120, 120, 0.2);
  filter: brightness(115%);
}

.DescriptionText {
  font-size: 16px;
  margin: 6px 0 0px 0px;
}

#CloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  border: thin solid rgba(255, 0, 0, 0.5);
  border-radius: 2px;
  cursor: pointer;
}

#TrackPackageText {
  font-size: 15px;
  width: 275px;
  border-radius: 15px;
  border: thin solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 20px rgb(158, 120, 120, 0.1);
  user-select: none;
  cursor: pointer;
  padding: 10px 15px 10px 15px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 200ms;
}

#TrackPackageText:hover {
  transform: translate(0, -4%);
  border: thin solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 20px rgb(158, 120, 120, 0.2);
}

#TrackPackageText:active {
  transform: translate(0, -1%);
  border: thin solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 20px rgb(158, 120, 120, 0.2);
}

#ShippingDialog {
  position: absolute;
  top: 7%;
  left: 50%;
  transform: translate(-50%);
  width: 65vw;
  min-width: 275px;
  background-color: white;
  padding: 20px 10px 20px 10px;
  border-radius: 8px;
  max-height: 86%;
  overflow-y: auto;
  margin: 0 0 100px 0;
}

.Information {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.InformationContainer {
  border: 1px solid black;
  box-sizing: content-box;
  margin: 0 20px 20px 20px;
  flex: 1 1 250px;
  padding: 0 0 0 0;
  border-radius: 5px;
  flex-direction: column;
  overflow: hidden;
}

#OrderInformation {
  box-sizing: content-box;
}

#ShippingInformation {
  box-sizing: content-box;
}

.InformationSubContainer {
  display: flex;
  justify-content: center;
  align-items: space-between;
}

.InformationSubColumn {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#DialogTitle {
  width: 100%;
  text-align: center;
  font-size: 28px;
}

.DialogSubtitle {
  width: 100%;
  text-align: center;
  font-size: 25px;
  color: white;
  padding: 10px 10px 10px 10px;
  margin-bottom: 5px;
  box-sizing: border-box;
}

.BlueTheme {
  background-color: #505f74;
}

.OrangeTheme {
  background-color: #ff6600;
}

.DialogHeaderText {
  width: 100%;
  text-align: center;
  font-size: 20px;
  margin-bottom: 0px;
  font-weight: 600;
}

.DialogSubText {
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin-bottom: 15px;
}

.NotesSection {
  width: 100%;
  margin: 10px 20px 10px 20px;
  box-sizing: content-box;
}

.Subtext {
  color: black;
  width: 100%;
  font-size: 16px;
  margin-bottom: 15px;
  text-align: left;
}

@keyframes Shiver {
  0% {
    transform: translate(0, 0);
  }

  44% {
    transform: translate(0, 0);
  }

  45% {
    transform: translate(-1px, 0);
  }

  46% {
    transform: translate(1px, 0);
  }

  47% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}
