#ErrorBody {
  position: absolute;
  height: 200px;
  width: 200px;
  background: none;
  border-radius: 50%;
  border: 1px solid black;
  margin-top: 50px;
  margin-bottom: 100px;
}

#ErrorBody::before {
  position: absolute;
  content: '';
  height: 10px;
  width: 10px;
  background: #505f74;
  border-radius: 50%;
  border: 0px solid black;
  top: 35%;
  left: 55%;
  animation-name: LookAroundLeft;
  animation-timing-function: linear;
  animation-duration: 6s;
  animation-delay: 1s;
  animation-fill-mode: backwards;
  animation-iteration-count: infinite;
}

#ErrorBody::after {
  position: absolute;
  content: '';
  height: 10px;
  width: 10px;
  background: #505f74;
  border-radius: 50%;
  border: 0px solid black;
  top: 35%;
  left: 85%;
  animation-name: LookAroundRight;
  animation-timing-function: linear;
  animation-duration: 6s;
  animation-delay: 1s;
  animation-fill-mode: backwards;
  animation-iteration-count: infinite;
}

#ErrorBody2 {
  position: absolute;
  height: 200px;
  width: 200px;
  background: none;
  border-radius: 50%;
  border: 0px solid black;
  margin-top: 50px;
  margin-bottom: 100px;
}

#ErrorBody2::before {
  position: absolute;
  content: '';
  height: 2px;
  width: 10px;
  background: #505f74;
  border-radius: 50%;
  border: 0px solid black;
  top: 32%;
  left: 55%;
  animation-name: LookAroundLeftEyebrow;
  animation-timing-function: linear;
  animation-duration: 6s;
  animation-delay: 1s;
  animation-fill-mode: backwards;
  animation-iteration-count: infinite;
  transform: rotate(45deg);
}

#ErrorBody2::after {
  position: absolute;
  content: '';
  height: 2px;
  width: 10px;
  background: #505f74;
  border-radius: 50%;
  border: 0px solid black;
  top: 32%;
  left: 85%;
  animation-name: LookAroundRightEyebrow;
  animation-timing-function: linear;
  animation-duration: 6s;
  animation-delay: 1s;
  animation-fill-mode: backwards;
  animation-iteration-count: infinite;
  transform: rotate(-45deg);
}

.PopperTitle {
  font-size: 22px;
  width: 100%;
  text-align: center;
}

#PopperBody li {
  margin-left: 20px;
  margin-bottom: 15px;
  color: #2c415f;
  text-align: justify;
}

#PopperBody {
  line-height: 1.25;
  min-height: 200px;
  width: 417px;
  background: white;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 10px;
  padding: 10px;
  padding-bottom: 0px;
  margin-left: 10px;

  animation-name: FadeIn;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

.ButtonContainer {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

#NotFound {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: 600;
  color: #ff6600;
  width: 150px;
  height: 35px;
  text-align: center;
  display: inline-block;
  animation-name: embolden;
  animation-timing-function: linear;
  animation-duration: 3s;
  animation-delay: 1s;
  animation-fill-mode: backwards;
  animation-iteration-count: infinite;
  justify-self: flex-end;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes LookAroundLeft {
  0% {
    transform: translateX(0%);
  }
  4% {
    transform: translateX(0%);
  }

  10% {
    transform: translate(0%, 100%);
  }
  14% {
    transform: translate(0%, 100%);
  }
  15% {
    transform: translate(50%, 100%);
  }
  25% {
    transform: translate(50%, 100%);
  }
  27% {
    transform: translate(-25%, 100%);
  }
  37% {
    transform: translate(-25%, 100%);
  }
  45% {
    transform: translate(0%, 0%);
  }
  60% {
    transform: translateX(0%);
  }
  75% {
    transform: translateX(-800%);
  }
  85% {
    transform: translateX(-800%);
  }
  90% {
    transform: translateX(0%);
  }
}

@keyframes LookAroundRight {
  0% {
    transform: translateX(0%);
  }
  4% {
    transform: translateX(0%);
  }

  10% {
    transform: translate(0%, 100%);
  }
  14% {
    transform: translate(0%, 100%);
  }
  15% {
    transform: translate(50%, 100%);
  }
  25% {
    transform: translate(50%, 100%);
  }
  27% {
    transform: translate(-25%, 100%);
  }
  37% {
    transform: translate(-25%, 100%);
  }
  45% {
    transform: translate(0%, 0%);
  }
  60% {
    transform: translateX(0%);
  }
  75% {
    transform: translateX(-800%);
  }
  85% {
    transform: translateX(-800%);
  }
  90% {
    transform: translateX(0%);
  }
}

@keyframes LookAroundLeftEyebrow {
  0% {
    transform: rotate(20deg) translateX(0%);
  }
  4% {
    transform: rotate(20deg) translateX(0%);
  }

  10% {
    transform: rotate(20deg) translate(0%, 100%);
  }
  14% {
    transform: rotate(20deg) translate(0%, 100%);
  }
  15% {
    transform: rotate(20deg) translate(50%, 100%);
  }
  25% {
    transform: rotate(20deg) translate(50%, 100%);
  }
  27% {
    transform: rotate(20deg) translate(-25%, 100%);
  }
  37% {
    transform: rotate(20deg) translate(-25%, 100%);
  }
  45% {
    transform: rotate(20deg) translate(0%, 0%);
  }
  60% {
    transform: rotate(0deg) translateX(0%);
  }
  75% {
    transform: rotate(0deg) translateX(-800%);
  }
  85% {
    transform: translateX(-800%);
  }
  85% {
    transform: rotate(0deg) translateX(-800%);
  }
  90% {
    transform: rotate(0deg) translateX(0%);
  }
  91% {
    transform: rotate(20deg) translateX(0%);
  }
}

@keyframes LookAroundRightEyebrow {
  0% {
    transform: rotate(-20deg) translateX(0%);
  }
  4% {
    transform: rotate(-20deg) translateX(0%);
  }

  10% {
    transform: rotate(-20deg) translate(0%, 100%);
  }
  14% {
    transform: rotate(-20deg) translate(0%, 100%);
  }
  15% {
    transform: rotate(-20deg) translate(50%, 100%);
  }
  25% {
    transform: rotate(-20deg) translate(50%, 100%);
  }
  27% {
    transform: rotate(-20deg) translate(-25%, 100%);
  }
  37% {
    transform: rotate(-20deg) translate(-25%, 100%);
  }
  45% {
    transform: rotate(-20deg) translate(0%, 0%);
  }
  60% {
    transform: rotate(0deg) translateX(0%);
  }
  75% {
    transform: rotate(0deg) translateX(-800%);
  }
  85% {
    transform: rotate(0deg) translateX(-800%);
  }
  85% {
    transform: rotate(0deg) translateX(-800%);
  }
  90% {
    transform: translateX(0%);
  }
  91% {
    transform: rotate(-20deg) translateX(0%);
  }
}

@keyframes embolden {
  0% {
    font-size: 20px;
    color: #ff6600;
  }
  80% {
    font-size: 20px;
    color: #ff6600;
  }
  90% {
    font-size: 22px;
    color: #ff3c00;
  }
  100% {
    font-size: 20px;
    color: #ff6600;
  }
}
