:root {
    --animation-duration: 200ms;
}

.CardBody {
    width: clamp(300px, 100%, 720px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border: thin solid rgba(200, 200, 200, 0.2);
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
    transition-duration: var(--animation-duration);
    padding: 15px 20px;
    border-radius: 10px;
    position: relative;
    height: 100%;
}

.CardBody:hover {
    border: thin solid rgba(200, 200, 200, 0.4);
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
}

.CardBody h1, .CardBody h2, .CardBody h3, .CardBody h4, .CardBody h5, .CardBody h6 {
    margin-bottom: 4px;
    width: 100%;
    text-align: center;
}


.CardBody h3 {
    font-size: 1.25rem;
}

.CardBody p {
    margin: 0;
    width: 100%;
    text-align: left;
}

.InformationIcon {
    position: absolute;
    top: 0;
    right: 5px;
}

.ToolTipBar{
    width: 100%;
    height: 25px;
    text-align: right;
}

.ToolTipIcon {
    color: rgb(100, 100, 100);
    animation-name: fadeIn;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-duration: var(--animation-duration);
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


.CardBody table {
    margin-top: 10px;
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    border-spacing: 0;
}


.CardBody td {
    padding: 0.5rem;
    text-align: left;
    text-overflow: ellipsis;
    word-break: break-all;
}

.CardBody td:nth-of-type(odd) {
    width: 75% !important;
}



.CardBody tr {
    border-top: thin solid rgba(200, 200, 200, 0.35);
    border-bottom: thin solid rgba(200, 200, 200, 0.35);
}

.CardBody thead tr:first-child {
    border-top: none;
    border-bottom: thin solid rgba(200, 200, 200, 1);
}

.CardBody tbody tr:nth-of-type(even) {
    background-color: rgba(200, 200, 200, 0.2);
}

.CardBody tbody tr:hover {
    background-color: rgba(200, 200, 200, 0.35);
}