.CenteredTableHeader {
    text-align: center;
    padding-right: 10px;
  }

.PaddedTableDefinition {
  padding-right: 10px;
}
  
  .CenteredTableDefinition {
    padding: 0;
  }
  
  .TableStyles {
    box-sizing: border-box;
    border: 2px solid #505f74;
    margin: 0;
    margin: 5px;
  }

  .TableBox {
    overflow-x: hidden;
    overflow-y: auto;
  }