.FrameContainer {
    height: 75vh;
    width: 100%;
    margin: 0;
    overflow: hidden;
    box-sizing: border-box;
    border: none;
    position: relative;
}

.FrameContainer iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}