.debugDiv {
    display: grid;
    margin-top: 20px;
    margin-left: 50px;
}

#debug-title {
    padding-bottom: 20px;
    font-weight: bold;
    text-decoration: underline;
}

.debug-td {
    padding-bottom: 40px;
}

.debug-td span {
    color: red;
    font-weight: bold;
    text-transform: uppercase;
}
