:root {
  --fontcolor: rgb(160, 160, 160);
}

#Container {
  background-color: #f7f7f7;
  color: rgb(160, 160, 160);
  height: fit-content;
  /* margin-left: 150px; */
}

.StandardBoxDesign {
  border: thin solid grey;
  padding: 25px;
}

.CardInformationContainer {
  width: 100%;
}

.InlineInformation {
}

.FullLineInputWrapper {
  background-color: white;
  padding: 10px 5px 10px 5px;
  margin-bottom: 5px;
}

.StyledInput {
  outline: none;
  width: 100%;
  border: none;
  font-size: 16px;
}

.Label {
  font-size: 16px;
}

.HolderNameInput {
  color: black;
  font-weight: 500;
  font-family: Roboto, Open Sans, Segoe UI, sans-serif;
  font-size: 16px;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  line-height: 1.2em;
  height: 1.2em;
  width: 100%;
}

.SpaceBetweenFields {
  padding-right: 15px;
}

.HolderNameInput:focus {
  outline: none;
}

.FormFieldContainer {
  height: 100px;
}

.PayButton {
  width: 100%;
  border: none;
  background-color: blue;
  color: white;
  padding: 10px;
  font-size: 20px;
  border-radius: 5px;
  display: inline-block;
  box-shadow: 3px 3px 5px rgba(0,0,0,.3);
  outline: 0;
}