* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
:root {
  --error-color: rgba(255, 25, 25, 1);
  --error-border-color: rgba(255, 25, 25, 0.75);
  --input-height: 3.5em;
  --input-padding: 0 0.95em;
  --input-color: inherit;
  --input-border: thin solid rgba(0, 0, 0, 0.3);
  --input-border-color: rgba(0, 0, 0, 0.3);
  --option-border: thin solid rgba(0, 0, 0, 0.15);
  --button-container-height: 50px;
  --input-margin-vertical: 1.5em;
  --option-select-padding-vertical: 0.1em;
}

.Container {
  opacity: 0.999 !important;
  width: 100%;
  height: min-content;
}

.Input {
  width: 100%;
  height: unset;
  color: var(--input-color);
  border: var(--input-border);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16.5px 0.95em;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  position: relative;
  font-size: 0.9rem;
  --vertical-padding: 16.5px;
  --side-padding: 14px;
  background: rgba(252, 252, 252);
}

.Input label {
  position: absolute;
  z-index: 100;
  transition-duration: 200ms;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  background: inherit;
}

.Input label {
  top: var(--vertical-padding);
  left: var(--side-padding);
  font-size: 1rem;
  background: inherit;
}

.Input label.Shifted {
  box-sizing: content-box;
  top: 0;
  left: calc(var(--side-padding) - var(--side-padding) / 4);
  transform: translateY(-50%);
  background: inherit;
  padding: 4px calc(var(--side-padding) / 4) 0 calc(var(--side-padding) / 4);
  font-size: 0.75rem;
  letter-spacing: 0.00938em;
  line-height: 1.4375em;
}

.PlaceholderText {
  opacity: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ValueText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.DisplayText {
  font-size: 1.1em;
}

.InputText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 90;
}

.InputArrow {
  flex: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Disabled {
  opacity: 60%;
}

.HelperText {
  margin: 3px 14px 0 14px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}
.Label {
  margin: 0;
  margin-bottom: 0.2em;
  font-weight: 600;
  font-size: 0.9rem;
  color: var(--input-color);
}

.OptionSelectExternal {
  background: black;
  opacity: 25%;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 4999;
  pointer-events: auto;
}

.RenderPage {
}

.OptionSelector {
  border: thin solid rgba(0, 0, 0, 0.1);
  padding: var(--option-select-padding-vertical) 0.5em;
  border-top-left-radius: 0.25em;
  border-top-right-radius: 0.25em;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 50vh;
  min-height: 200px;
  background: white;
  z-index: 5000;
  animation: slide 500ms ease-in-out forwards;
  overflow-x: hidden;
  box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.2);
  font-family: Arial, Helvetica, sans-serif;
}

.OptionSelector.LoggedIn {
  bottom: 72px;
}

.OptionSelectorInputBox {
  position: relative;
  width: 90%;
  margin: 1em auto 0.5em auto;
  border: var(--input-border);
  border-radius: 30px;
  overflow: hidden;
  padding: var(--input-padding);
  box-sizing: border-box;
  font-size: 1.1em;
  height: var(--input-height);
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
}

.OptionSelector input[data-search] {
  width: 100%;
  flex: 80 60;
  border-radius: 0;
  border: none;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
}

.OptionSelector .PlaceHolder {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.OptionSelectorInputIcon {
  position: absolute;
}

.OptionSelector input[type='radio'] {
  accent-color: var(--datasight-blue);
}

.IconContainer {
  flex: 15;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ClearIconContainer {
  padding-left: 1em;
}

.OptionList {
  list-style-type: none;
  height: calc(
    100% -
      (
        var(--input-height) + 2 * var(--input-margin-vertical) + 2 *
          var(--option-select-padding-vertical)
      )
  );
  overflow: scroll;
  margin: 0;
  padding: 0 1em;
}

.FancyScrollBar::-webkit-scrollbar {
  height: 0;
  width: 0.3em;
  border: 1px solid #d5d5d5;
}

.FancyScrollBar::-webkit-scrollbar-thumb {
  background-color: var(--datasight-blue);
}

.FancyScrollBar::-webkit-scrollbar-track {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
}

.Option {
  font-size: 1rem;
  padding: 0.5rem;
  border-bottom: var(--option-border);
  display: flex;
  gap: 1em;
}

.Option input {
  margin-top: 0.3em;
}

.Option:hover {
  background: rgba(0, 0, 0, 0.15);
}

.Option.Selected {
  background: rgba(0, 0, 0, 0.1);
}

.ButtonContainer {
  height: var(--button-container-height);
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 0.5em 0.5em;
  border-top: var(--input-border);
}

.ButtonContainer > [data-cancel] {
  background: var(--datasight-blue);
}

.ButtonContainer > [data-accept] {
  background: var(--datasight-orange);
}

/* .Option.Selected::after {
  content: '';
  animation: ripple 500ms ease-in-out forwards;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0;
} */

@keyframes slide {
  0% {
    transform: translateY(calc(100% + 72px));
  }

  100% {
    transform: translateY(0%);
  }
}

/* @keyframes ripple {
  from {
    opacity: 0.5;
    transform: scale3d(0.75, 0.75, 1);
  }

  to {
    opacity: 0;
    transform: scale3d(2, 2, 1);
  }
} */
