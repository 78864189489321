.Content {
  width: 30vw;
}

.Buttons {
  margin: 10px !important;
}

.Button {
  width: 200px;
}

.Details {
  width: 100%;
  text-align: center;
  overflow: hidden;
  box-sizing: border-box;
  margin-top: 0px;
  margin-bottom: 20px;
}
