.dots {
  display: flex;
  padding: 10px 10px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.dot {
  border: none;
  width: 10px;
  height: 10px;
  background: #c5c5c5;
  border-radius: 50%;
  margin: 5px 5px;
  padding: 5px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: #000;
}

.arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: rgba(92, 92, 92, 0.75);
  cursor: pointer;
}

.arrow--left {
  left: 15px;
}

.arrow--right {
  left: auto;
  right: 15px;
}

.arrow--disabled {
  display: none;
  transition: 250ms;
}

[class^='number-slide'],
[class*=' number-slide'] {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 5px 10px 5px 10px;
  margin: 0 !important;
  width: 100%;
}

.navigation-wrapper {
  position: relative;
  width: 100%;
}
.keen-slider {
  height: 100%;
  width: 100%;
}
