.standardAnchor {
  color: inherit;
  font-weight: 500;
  opacity: 0.8;
  filter: invert();
  background-color: transparent;
}

.standardAnchor:link {
  color: #1a0db0;
}

.standardAnchor:visited {
  color: #6600a6;
}
