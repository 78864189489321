.SpendingChartPicker {
    flex: 50;
}
.PickerHeaderIcon {
    margin-bottom: 2px;
    cursor: help;
}

.PickerHeaderText {
    margin: 0;
}
.PickerHeader {
    text-align: center;
    margin: 0 0 15px 0;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
}

.LabeledInput {
    display: flex;
    align-items: center;
}

.LabeledInput:hover,
.LabeledInput label,
.LabeledInput input:hover {
    cursor: pointer;
}

.SpendingChartPicker label {
    margin: 0 0 0 5px;
}