.Container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap-reverse;
}

.TableContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-basis: 150px;
}

.GraphContainer {
  margin: 20px;
  flex: 4;
  flex-basis: 450px;
}
