.PickerBody {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: rgb(120, 120, 120);
}

.PickerTab {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  user-select: none;
  border: thin solid #d8d8d8;
  border-radius: 5px;
  background-color: #f5f5f5;
  transition-duration: 300ms;
  cursor: pointer;
}

.PickerTab:hover {
  background-color: #c7c7c7;
  color: rgb(25, 25, 25);
}

.SelectedTab {
  background-color: rgb(216, 216, 216);
  color: rgb(70, 70, 70);
}
