.DescriptionText {
  font-size: 16px;
  margin: 6px 0 0px 0px;
}

#CloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  border: thin solid rgba(255, 0, 0, 0.5);
  border-radius: 2px;
  cursor: pointer;
}

#TrackPackageText {
  font-size: 15px;
  width: 275px;
  border-radius: 15px;
  border: thin solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 20px rgb(158, 120, 120, 0.1);
  user-select: none;
  cursor: pointer;
  padding: 10px 15px 10px 15px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 200ms;
}

#TrackPackageText:hover {
  transform: translate(0, -4%);
  border: thin solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 20px rgb(158, 120, 120, 0.2);
}

#TrackPackageText:active {
  transform: translate(0, -1%);
  border: thin solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 20px rgb(158, 120, 120, 0.2);
}

#ShippingDialog {
  position: absolute;
  top: 7%;
  left: 50%;
  transform: translate(-50%);
  width: calc(300px + 50vw);
  max-width: 100vw;
  min-width: 275px;
  background-color: white;
  padding: 20px 10px 20px 10px;
  border-radius: 8px;
  max-height: 86%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 0 100px 0;
}

.Information, .Actions {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.InformationLeft {
  justify-content: flex-start;
}

.ActionsCenter {
  justify-content: center;
}

.InformationLeft ul {
  font-size: 18px;
  margin-left: 18px;
}

.InformationContainer {
  border: thin solid rgba(0, 0, 0, 0.25);
  box-sizing: content-box;
  margin: 0 20px 20px 20px;
  flex: 1 1 250px;
  padding: 0 0 0 0;
  border-radius: 5px;
  flex-direction: column;
  overflow: hidden;
}

#OrderInformation {
  box-sizing: content-box;
}

#ShippingInformation {
  box-sizing: content-box;
}

.InformationSubContainer {
  display: flex;
  justify-content: center;
  align-items: space-between;
}

.InformationSubColumn {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#DialogTitle {
  width: 100%;
  text-align: center;
  font-size: 28px;
}

.DialogSubtitle {
  width: 100%;
  text-align: center;
  font-size: 25px;
  color: white;
  padding: 10px 10px 10px 10px;
  margin-bottom: 5px;
  box-sizing: border-box;
}

.BlueTheme {
  background-color: #505f74;
}

.OrangeTheme {
  background-color: #ff6600;
}

.DialogHeaderText {
  width: 100%;
  text-align: center;
  font-size: 20px;
  margin-bottom: 0px;
  font-weight: 600;
}

.DialogSubText {
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin-bottom: 15px;
}

.NotesSection {
  width: 100%;
  margin: 10px 20px 10px 20px;
  box-sizing: content-box;
}

.Subtext {
  color: black;
  width: 100%;
  font-size: 16px;
  margin-bottom: 15px;
  text-align: left;
}

@keyframes Shiver {
  0% {
    transform: translate(0, 0);
  }

  44% {
    transform: translate(0, 0);
  }

  45% {
    transform: translate(-1px, 0);
  }

  46% {
    transform: translate(1px, 0);
  }

  47% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

.OrderDetailsTable {
  width: 100%;
  border: thin solid rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 15px;
}


.OrderDetailsTable td {
  font-size: 16px;
  padding: 2px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}


.OrderDetailsTable th {
  font-size: 20px;
  border-bottom: thin solid rgba(0, 0, 0, 0.5);
  padding: 2px 10px;
}
