.SpendingCharts{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.SpendingCharts section{
    flex: 1;
    flex-basis: 600px;
}

.CenteredSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}

.SectionHeader {
    width: 100%;
    text-align: center;
    font-size: 2rem;
    margin-bottom: 0.25rem;
}

.SectionUnderline {
    width: 90%;
    height: 1px;
    background-color: #505F74;
    margin: 0.5rem auto 1rem auto;
}


.GraphSection {
    margin-bottom: 20px;
}

.ScoreCardContainer {
    margin: 20px auto 0 auto;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
}

.SectionPad {
    margin: 40px auto 40px auto;
}

.ScoreCardContainer>div {
    flex: 1;
    flex-basis: 300px;
}

.CardWrapper {
    display: flex;
    justify-content: center;
}

.LongLoadMessage {
    width: 100%;
    text-align: center;

}

.LongLoadMessage span {
    display: block;
    
}

.LongLoadMessage span {
    font-size: 1rem;
}

.LongLoadMessage span:first-child {
    font-size: 1.25rem;
}

