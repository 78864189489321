.TestPackageCard {
  border: thin solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 1em;
  padding-bottom: 0.5em;
  min-width: 240px;
  flex: 0 1 47.5% !important;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
  background-image: linear-gradient(
    rgba(255, 255, 255, 1),
    rgba(80, 95, 116, 0.1)
  );
  overflow: hidden;
  height: min-content;
}

.TestPackageCard div {
  font-size: 1.25em;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;
}

.TestPackageCard > div {
  border-bottom: thin solid rgba(0, 0, 0, 0.25);
  padding-bottom: 0.4em;
  margin-bottom: 1em;
}

.TestPackageCard h1 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

.TestPackageCard p {
  margin: 0.5em 0;
  font-size: 1.15em;
}

.TestPackageCard p span:nth-child(1) {
  font-weight: bold;
}

.TestPackageCard p span:nth-child(2) {
  font-weight: normal;
  font-size: 1em;
}

.Icon {
  cursor: pointer;
}

#Edit {
  color: rgba(0, 0, 0, 0.8);
}

#Delete {
  color: rgba(200, 0, 0, 1);
}
@media only screen and (max-width: 480px) {
  .TestPackageCard {
    flex: 1 1 !important;
    width: 100%;
  }
}
