.Banner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 0px 20px 0px 20px;
}

.Contents {
  background-color: rgba(225, 234, 245, 0.8);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-width: 280px;
  max-width: 800px;
  width: 70%;
  min-height: 60px;
  height: 100%;
  border-radius: 3px;
  padding: 5px 10px 5px 10px;
  box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.09);
}

.Text {
  font-size: 14px;
  text-align: center;
  display: block;
  max-width: 65%;
  margin: 5px;
  text-align: justify;
}

.TextHeader {
  font-size: 24px;
  text-align: center;
  display: block;
  margin: 5px;
  font-weight: 600;
}
