.TextArea {
  width: 100%;
  position: relative;
  font-size: 1rem;
  --vertical-padding: 16.5px;
  --side-padding: 14px;
  --focused-border: 2px solid var(--datasight-orange);
  background: rgba(252, 252, 252);
  cursor: text;
}

.TextArea textarea {
  width: 100%;
  position: relative;
  padding: var(--vertical-padding) var(--side-padding);
  outline: none;
  border: thin solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  z-index: 10;
  resize: vertical;
  line-height: 1.4375em;
  box-sizing: border-box;
  text-align: justify;
  background: inherit;
}

.TextArea textarea.Focused {
  border: var(--focused-border);
  padding: calc(var(--vertical-padding) - 1px) calc(var(--side-padding) - 1px);
  background: inherit;
}

.TextArea label {
  top: var(--vertical-padding);
  left: var(--side-padding);
  font-size: 1rem;
  background: inherit;
  position: absolute;
  z-index: 11;
  transition-duration: 200ms;
  color: rgba(0, 0, 0, 0.6);
  background: inherit;
  user-select: none;
  cursor: text;
}

.TextArea label.Shifted {
  box-sizing: content-box;
  top: 0;
  left: calc(var(--side-padding) - var(--side-padding) / 4);
  transform: translateY(-50%);
  background: inherit;
  padding: 4px calc(var(--side-padding) / 4) 0 calc(var(--side-padding) / 4);
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.00938em;
  line-height: 1.4375em;
}

.TextArea label.Shifted.Focused {
  color: var(--datasight-orange-light);
  background: inherit;
}
