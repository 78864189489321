.Button {
  position: absolute;
  left: 0.6em;
  top: 0.7em;
  color: red;
  cursor: pointer;
}

.Button.LoggedIn {
  left: 0.6em;
  top: 0.5em;
}

.Button.LoggedIn.Mobile {
  left: 0.6em;
  top: -0.4em;
}
