.FilterBox {
    padding: 20px;
    padding-bottom: 30px;
    /* border: 5px solid black; */
    border: thin solid rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin: 10px;
    flex: 1;
    box-shadow: 8px 5px 15px rgba(0, 0, 0, 0.10);
}

.FilterList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    background-color: white;
    gap: 20px;
    width: 100%;
}

.Filter {
    flex: 1;
    /* flex-basis: 225px; */
}

.Title {
    margin-bottom: 15px;
    text-align: center;
}