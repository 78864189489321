:root {
  --nav-button-container-height: 3rem;
  --nav-button-margin-top: 1rem;
  --page-card-border: thin solid rgba(0, 0, 0, 0.25);
  --off-white: #faf9f6;
  --page-card-margin-top: 0.25rem;
  --page-card-mobile-help-margin: 4rem;
  --nav-button-container-margin: 0.5em;
  --page-padding-vertical: 0.25em;
}

.NoBorders {
  border: none !important;
  padding: none !important;
}

.NoExpand {
  flex: 0 !important;
}

.Flexbox {
  display: flex;
  width: 100%;
  gap: 1em;
}

.FullWidth {
  width: 100%;
}

.PageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.DisabledWindow {
  user-select: none;
}

.PageContainer {
  position: relative;
  height: 100%;
  width: 100%;
  font-family: Roboto, Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

.PageHeader {
  height: 20vh;
  padding: 2em 0 0 0;
}

.Comments {
  text-align: justify;
  text-justify: distribute;
  line-break: auto;
  font-size: 16px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  min-height: 60px;
  padding: 8px 16px 8px 16px;
  margin-top: 8px;
  width: 100%;
  background: rgba(0, 0, 0, 0.025);
}

/* Added specificity to overwrite the "Unknown." text of non-comment properties.*/
.Property.Comments:empty::after {
  content: 'No comments.';
  font-style: italic;
  opacity: 0.8;
}

.Comments:empty::after {
  content: 'No comments.';
  font-style: italic;
  opacity: 0.8;
}

.ProgressStepperBox {
  width: 100%;
  left: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
}

.NotFoundModule {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin: 2rem 0;
}

.PageModal {
  background: rgba(255, 255, 255, 1);
  border: solid thin rgba(0, 0, 0, 0.25);
  position: absolute;
  top: var(--page-card-mobile-help-margin);
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 10px;
  z-index: 9999;
  padding: 1em;
  display: flex;
  flex-direction: column;
  height: 75%;
  overflow-y: auto;
  overflow-x: hidden;
  backdrop-filter: blur(6px);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.PageCard .FinePrintParagraph {
  width: 100%;
  text-align: justify;
  text-justify: distribute;
  font-size: 0.85rem;
  line-height: 1.1rem;
  line-break: normal;
}

.PageCard {
  position: relative;
  /* overflow-x: hidden; */
  overflow-y: auto;
  --nav-top-height: 67px;
  --nav-bot-height: 72px;
  background: rgba(252, 252, 252);
}

.MainTitle {
  display: block;
  /* margin-bottom: 0.5rem; */
  padding-bottom: 0.5rem;
  width: 100%;
  font-size: 1.5rem;
  text-align: center;
}

.SectionTitle {
  width: 100%;
  font-size: 1.3rem;
  text-align: center;
  display: block;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: thin solid rgba(0, 0, 0, 0.2);
}

.SubTitle {
  display: block;
  margin-top: -0.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
  height: fit-content;
  text-align: center;
  font-size: 0.85rem;
  line-height: 1.1rem;
}

.BlockSpan {
  display: block;
}

.ButtonContainer {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  gap: 1rem;
  margin: 0.5rem 0;
  justify-content: space-between;
  align-self: flex-end;
  justify-self: flex-end;
  width: 100%;
}

.Button {
  /* flex: 1; */
  grid-row-start: 1;
}

.Button.Left {
  grid-column-start: 1;
}

.Button.Right {
  grid-column-start: 2;
}

.LonelyVertical {
  margin: 1.5em 0;
}

.BotGap {
  margin: 0 0 1.5em 0;
}

.DetailEntryContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.SplitContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.DetailEntryContainer:not(:last-child) {
  margin-bottom: 2em;
}

.CenteredContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.EntryScreen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  width: 100%;
}

.SpacedColumnContainer {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.DetailSectionHeader {
  font-size: 1.2rem;
  font-weight: 600;
}

.DetailSection {
  display: flex;
  flex-direction: column;
  border-top: thin solid rgba(0, 0, 0, 0.15);
  padding: 1.15rem 0 0.25rem 0;
  justify-content: flex-start;
}

.DetailSection > ul {
  list-style-type: none;
}

.Details {
  list-style-type: none;
}

.Details > li:not(:last-child) {
  margin-bottom: 0.65em;
}

.SectionSeparator {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
}

.PropertyLabel {
  display: block;
  font-weight: 600;
  font-size: 1.1rem;
  text-align: left;
}

.Property {
  display: inline-block;
  font-weight: 500;
  font-size: 1rem;
}

.Property:empty::after {
  content: 'Unknown.';
  font-style: italic;
  opacity: 0.8;
}

.PropertyBox {
  text-align: left;
  margin: 0;
  width: min-content;
  display: inline-block;
}

.ConditionBox {
  transform: translateY(30%);
  margin-right: 0.5rem;
}

.LargeIconBox {
  width: 100%;
  height: min-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

.LabeledCheckboxContainer {
  display: flex;
  align-items: center;
}

.LabeledCheckboxContainer > label {
  font-size: 1.05rem;
  font-weight: 500;
  margin: auto auto auto 0;
}

.TargetButton {
  display: flex;
  flex: 15 1;
  margin: 0;
  border: thin solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 200ms;
}

.TargetButton:hover {
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: 0.2em 0.2em 0.7em rgba(0, 0, 0, 0.1);
}
.TargetButton:active {
  transform: translateY(2%);
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0.1em 0.1em 0.5em rgba(0, 0, 0, 0.15);
}

.BrokenHeartIcon {
  color: rgba(200, 0, 0, 1);
}

.TroubleshootIcon {
  color: rgba(0, 0, 0, 0.6);
}

.LockPersonIcon {
}

.SettingsIcon {
  animation: spin linear 8s infinite;
}

.TopMargined {
  margin-top: 3rem;
}

.LoggedIn {
  --logged-in-padding: 24px;
  --logged-in-border-net: 5px;
}

.LoggedInNavButtonContainer {
  height: var(--nav-button-container-height);
  margin: var(--nav-button-margin-top) 0 0 0;
}

.ErrorText {
  color: rgba(200, 0, 0, 1);
}

.PageModal::-webkit-scrollbar {
  height: 0;
  width: 0.3em;
  border: 1px solid #d5d5d5;
}

.PageModal::-webkit-scrollbar-thumb {
  background-color: var(--datasight-blue);
}

.PageModal::-webkit-scrollbar-track {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
}

.PageCard::-webkit-scrollbar {
  height: 0;
  width: 0.3em;
  border: 1px solid #d5d5d5;
}

.PageCard::-webkit-scrollbar-thumb {
  background-color: var(--datasight-blue);
}

.PageCard::-webkit-scrollbar-track {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
}

.Rating p {
  margin: 0 0 0.5em 0;
}

.Rating label {
  font-weight: 600;
  font-size: 1.1em;
  margin: 0;
}

@media only screen and (max-width: 480px) {
  .PageCard {
    width: 98vw;
    padding: 1em;
    margin-top: var(--page-card-margin-top);
    height: calc(100vh - 2 * var(--page-card-margin-top));
    border: var(--page-card-border);
    box-shadow: 0.2rem 0.2rem 1rem rgba(0, 0, 0, 0.32);
    border-radius: 0.25rem;
    min-height: 400px;
  }

  .PageModal {
    width: calc(98vw - 25px);
  }

  .PageCard.LoggedIn {
    /* width: 100%;
    padding: 0; */
    padding-top: 0;
    border: none;
    border-radius: none;
    border-bottom: var(--page-card-border);
    box-shadow: none;
    height: calc(
      100vh -
        calc(
          var(--nav-bot-height) + 2 * var(--logged-in-padding) +
            var(--logged-in-border-net) + var(--nav-button-container-height)
        )
    );
  }

  .ProgressStepperBox {
    height: 8rem;
  }

  .LoggedInNavButtonContainer {
    width: 100%;
  }
}

@media only screen and (min-width: 480px) {
  .PageCard {
    width: clamp(480px, 850px, 100%);
    padding: 0.25em 3em;
    margin-top: var(--page-card-margin-top);
    background-color: rgba(0, 0, 0, 0);
    overflow-y: unset;
  }

  .NoExpand {
    flex-basis: 300px !important;
  }

  .LoggedInNavButtonContainer {
    width: clamp(480px, 850px, 100%);
    padding: 0.25em 3em;
  }

  .PageModal {
    width: clamp(430px, 1200px, calc(100% - 50px));
    height: 91%;
  }

  .EntryScreen {
    height: calc(100vh - 3 * var(--page-card-margin-top));
    min-height: calc(
      100vh - var(--nav-top-height) - var(--logged-in-padding) - 2 *
        var(--nav-button-container-margin) - var(--page-padding-vertical)
    );
  }

  .EntryScreen.WithBottleButton {
    min-height: calc(
      100vh - var(--nav-top-height) - var(--logged-in-padding) - 2 *
        var(--nav-button-container-margin) - var(--page-padding-vertical) -
        var(--nav-button-margin-top) - var(--nav-button-container-height)
    );
  }

  .EntryScreen.LoggedIn {
    height: inherit;
  }

  .PageCard.LoggedIn {
    margin: 0;
    padding: 0 3em;
  }

  @media only screen and (max-width: 960px) {
    .EntryScreen.LoggedIn {
      height: inherit;
      min-height: calc(
        100vh - var(--nav-top-height) - var(--logged-in-padding) - 2 *
          var(--nav-button-container-margin) - var(--page-padding-vertical) -
          var(--nav-bot-height)
      );
    }

    .EntryScreen.LoggedIn.WithBottleButton {
      height: inherit;
      min-height: calc(
        100vh - var(--nav-top-height) - var(--logged-in-padding) - 2 *
          var(--nav-button-container-margin) - var(--page-padding-vertical) -
          var(--nav-button-margin-top) - var(--nav-button-container-height) -
          var(--nav-bot-height)
      );
    }
  }

  .Wrapper {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }

  .DetailSection > .Wrappable {
    flex: 1;
    flex-basis: 300px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
