.root {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.plantName {
  font-size: 16px;
  font-weight: 600;
  color: black;
}
