.label {
  width: 100%;
  margin: 5px 0 5px 0;
  display: inline-block;
  color: rgba(255, 255, 255, 0.33);
  font-weight: 100;
  font-size: 1rem;
  mix-blend-mode: difference;
}

.error {
  color: rgba(255, 0, 0, 0.88);
  mix-blend-mode: normal;
  font-weight: 600;
}
