#Banner {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(7px);
}

#PrimaryFont {
    text-align: center;
    color: rgba(255, 50, 50);
    font-size: 40px;
    margin-top: 10%;
    font-weight: 600;
    -webkit-text-stroke: 1px black;
}

#SecondaryFont {
    text-align: center;
    color: rgba(255, 50, 50);
    font-size: 35px;
    -webkit-text-stroke: 0.5px black;
}